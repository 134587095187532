<script>
/***
 Name: before
 Code: sa0ChunLuyu
 Time: 2021/11/16 09:24
 Remark: 预委托单管理
 */
import List from './list/list.vue'
import Item from './item/item.vue'

export default {
  components: {List, Item},
  data() {
    return {}
  },
  mounted() {
  },
  methods: {
    reloadList() {
      this.$refs.list.getBeforeList()
    }
  }
}
</script>
<template>
  <div>
    <a-space>
      <List ref="list"></List>
      <Item :reloadList="reloadList"></Item>
    </a-space>
  </div>
</template>
<style scoped>

</style>
