<script>
/***
 Name: create
 Code: sa0ChunLuyu
 Time: 2021/11/16 11:13
 Remark: 创建
 */
export default {
  props: ['reloadList'],
  data() {
    return {
      create_data: {
        name: '',
        com_name: '',
        task_type: 1,
        com_per: '',
        com_phone: '',
        get_time: '',
        remark: '',
      }
    }
  },
  mounted() {
  },
  methods: {
    createClick() {
      if (this.create_data.name === '') return layer.msg('请输入预委托单名称')
      if (this.create_data.com_name === '') return layer.msg('请输入委托单位名称')
      this.$sa0.post({
        url: this.$api('创建预任务单'),
        data: this.create_data
      }).then((response) => {
        this.$sa0.response({
          response: response.data,
          then: (response) => {
            layer.msg('创建成功')
            this.$router.push('/weituodanguanli/before/' + response.data.id)
            this.reloadList()
          },
          error: (response) => {
            layer.msg(response.message)
          },
        })
      })
    }
  }
}
</script>
<template>
  <div style="width: 800px;">
    <div class="title_wrapper">创建预委托单</div>
    <a-collapse :defaultActiveKey="1">
      <a-collapse-panel key="1" header="基本信息">
        <div>
          <table class="layui-table">
            <colgroup>
              <col width="120">
              <col width="200">
              <col width="100">
              <col>
            </colgroup>
            <tbody>
            <tr>
              <td>预委托单名称</td>
              <td colspan="3">
                <input type="text" v-model="create_data.name" class="input_wrapper">
              </td>
            </tr>
            <tr>
              <td>委托单位名称</td>
              <td colspan="3">
                <input type="text" v-model="create_data.com_name" class="input_wrapper">
              </td>
            </tr>
            <tr>
              <td>委托类型</td>
              <td>
                <a-select v-model="create_data.task_type" class="w-full">
                  <a-select-option :value="1">内部</a-select-option>
                  <a-select-option :value="2">外部</a-select-option>
                </a-select>
              </td>
              <td>办理时间</td>
              <td>
                <a-date-picker class="w-full" v-model="create_data.get_time" show-time format="YYYY-MM-DD HH:mm:ss"
                               valueFormat="YYYY-MM-DD HH:mm:ss"/>
              </td>
            </tr>
            <tr>
              <td>联系人</td>
              <td><input type="text" v-model="create_data.com_per" class="input_wrapper"></td>
              <td>电话</td>
              <td><input type="text" v-model="create_data.com_phone" class="input_wrapper"></td>
            </tr>
            <tr>
              <td>备注</td>
              <td colspan="3">
                <input type="text" v-model="create_data.remark" class="input_wrapper">
              </td>
            </tr>
            </tbody>
          </table>
          <div>
            <button @click="createClick()" class="layui-btn layui-btn-normal">保存</button>
          </div>
        </div>
      </a-collapse-panel>
      <a-collapse-panel key="2" header="自定义信息" :disabled="true"></a-collapse-panel>
      <a-collapse-panel key="3" header="绑定样品" :disabled="true"></a-collapse-panel>
    </a-collapse>
  </div>
</template>
<style scoped>
td {
  line-height: 40px;
  padding: 0 10px;
}

.input_wrapper {
  width: 100%;
  height: 100%;
}

.title_wrapper {
  font-size: 26px;
  font-weight: bold;
}
</style>
