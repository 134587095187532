<script>
/***
 Name: yp
 Code: sa0ChunLuyu
 Time: 2021/11/16 16:59
 Remark: 样品组
 */
export default {
  data() {
    return {}
  },
  mounted() {
  },
  methods: {
    getGroupInfo(id) {

    }
  }
}
</script>
<template>
  <div>
    yp
  </div>
</template>
<style scoped>

</style>
