<script>
/***
 Name: item
 Code: sa0ChunLuyu
 Time: 2021/11/16 10:56
 Remark: 内容
 */
import Create from './create.vue'
import Update from './update.vue'

export default {
  props: ['reloadList'],
  components: {Create, Update},
  data() {
    return {}
  },
  mounted() {
  },
  methods: {}
}
</script>
<template>
  <div class="item_wrapper">
    <Create :reloadList="reloadList" v-if="Number($route.params.id) === 0"></Create>
    <Update :reloadList="reloadList" v-if="Number($route.params.id) !== 0"></Update>
  </div>
</template>
<style scoped>
.item_wrapper {
  margin-left: 270px;
}
</style>
